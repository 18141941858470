import * as React from 'react';
import { Link } from 'gatsby';
import { siteTitle } from '../components/layout.module.css';
import Footer from '../components/footer';
import { Seo } from '../components/seo';

// styles
const bigContainerStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
};
const biglyStyle = {
    color: '#fff',
    fontFamily: 'Niveau Grotesk, serif',
    fontSize: 'min(40vw, 500px)',
    fontWeight: '100',
    margin: '0',
};
const short = {
    alignItems: 'center',
    display: 'flex',
    height: '11vh',
    justifyContent: 'center',
};
const tall = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    justifyContent: 'center',
};

const NotFoundPage = () => {
    return (
        <main>
            <title>Not found</title>
            <div style={bigContainerStyle}>
                <div style={short}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <header className={siteTitle}>RTPOWELLdesign</header>
                    </Link>
                </div>
                <div style={tall}>
                    <h1 style={biglyStyle}>404</h1>
                    <p>Oh no! It appears you've fallen through a wormhole.<br/>
                    <a href="http://rtpowelldesign.com">Try clicking your heels.</a></p>
                </div>
            </div>
            <Footer />
        </main>
    );
};

export default NotFoundPage;

export const Head = () => <Seo title='Page not Found' />;
